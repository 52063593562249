<anagrafica-esercizi>
    <div>
        <div>
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="" text="Anagrafica esercizi" />
            </breadcrumb>
        </div>
        <heading-page ckey="{ ckey }" text="Anagrafica esercizi" link="/{ ckey }/create" search="1" refresh="1"></heading-page>
        <generic-table ckey='{ ckey }' th_array='{ get_th_array() }' td_array='{ get_td_array() }' title_delete="{ getTitleModalDelete.bind(this) }" text_delete="{ getTextModalDelete.bind(this) }"></generic-table>
    </div>

    <script>
        import eventBus from '/src/utils/event-bus'
        import { KEY_ESERCIZIO } from '/src/utils/constants'
        import * as events from '/src/utils/events'

        export default {

            ckey: KEY_ESERCIZIO,

            get_th_array() {
                const th_array = []
                th_array.push({
                    column: 'descrizione',
                    label: 'Esercizio',
                    sorting: true
                })
                th_array.push({
                    column: 'attrezzoDescrizione',
                    label: 'Attrezzo',
                    sorting: true
                })
                th_array.push({
                    column: 'categoriaDescrizione',
                    label: 'Categoria',
                    sorting: true,
                    hidesm: true
                })
                th_array.push({
                    column: 'punteggio',
                    label: 'Punteggio',
                    sorting: true,
                    hidesm: true
                })
                th_array.push({
                    column: 'tipologia',
                    label: 'Tipologia',
                    sorting: true,
                    hidesm: true
                })
                return th_array
            },

            get_td_array() {
                const td_array = []
                td_array.push({
                    field: 'descrizione',
                    highlight: true
                })
                td_array.push({
                    field: 'attrezzoDescrizione',
                    highlight: false
                })
                td_array.push({
                    field: 'categoriaDescrizione',
                    highlight: false,
                    hidesm: true
                })
                td_array.push({
                    field: 'punteggio',
                    highlight: false,
                    hidesm: true
                })
                td_array.push({
                    field: 'tipologia',
                    highlight: false,
                    hidesm: true
                })
                return td_array
            },

            onBeforeMount(props, state) {
                this.state = {
                    items: {
                        count: 0,
                        results: []
                    }
                }

                eventBus.on(events.ITEMS_CHANGED, this.handleTableItemsChanged)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.ITEMS_CHANGED, this.handleTableItemsChanged)
            },

            handleTableItemsChanged(e) {
                if (e.ckey === this.ckey) {
                    this.update({
                        items: e.items
                    })
                }
            },
            
            getTitleModalDelete(e) {
                if (e.ckey === this.ckey) {
                    return "Eliminazione esercizio"
                }
            },

            getTextModalDelete(e) {
                if (e.ckey === this.ckey) {
                    const esercizio = this.state.items.results.find(item => item.id === e.item_id)
                    return "Stai per cancellare l'esercizio " + esercizio.get('descrizione') + ". L'azione è irreversibile. Vuoi continuare?"
                }
            }
        }

    </script>
</anagrafica-esercizi>
