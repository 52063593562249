/* Riot WIP, @license MIT */
const ATTRIBUTE = 0;
const EVENT = 1;
const TEXT = 2;
const VALUE = 3;

const expressionTypes = {
  ATTRIBUTE,
  EVENT,
  TEXT,
  VALUE,
};

export { ATTRIBUTE, EVENT, TEXT, VALUE, expressionTypes as default };
