<table-td>
    <div scope="col" class="relative whitespace-nowrap text-sm { get_classes() } { props.center ? 'text-center' : '' } { props.hidemd ? 'hidden md:table-cell' : '' } { props.hidesm ? 'hidden sm:table-cell' : '' }"><slot /></div>

    <script>
        export default {
            get_classes() {
                let classes = ''
                if (this.props.condensed) {
                    classes += ' py-2'
                } else {
                    classes += ' py-4'
                }
                if (this.props.first) {
                    classes += ' pl-4 pr-3 sm:pl-0'
                }
                if (this.props.last) {
                    classes += ' pl-3 pr-4 sm:pr-0 text-right'
                }
                if (!this.props.first && !this.props.last) {
                    classes += ' px-3'
                }
                if (this.props.highlight) {
                    classes += ' font-medium text-gray-900'
                } else {
                    classes += ' text-gray-500'
                }
                return classes
            }
        }
    </script>
</table-td>
