import '/src/services/parse-init'; // Configura Parse
import * as riot from 'riot'
import App from './components/app.riot';
import registerGlobalComponents from '/src/utils/register-global-components';
import { Route, Router } from '@riotjs/route'

// Registra i componenti globali come header, footer, ecc
registerGlobalComponents();

// Registra globalmente Router e Route
riot.register('router', Router)
riot.register('route', Route)

// Monta il componente principale dell'applicazione
const mountApp = riot.component(App)

const app = mountApp(document.getElementById('root'))
