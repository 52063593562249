<choose-week>
    <div class="flex justify-between items-center text-gray-900">
        <button type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500" onclick="{
                    change_week.bind(this, -1)
                }">
            <span class="sr-only">Settimana precedente</span>
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
            </svg>
        </button>
        <div class="text-center">
            <div class="font-semibold">
                Settimana { moment(state.current_week).format('ww') }
            </div>
            <div class="text-sm font-semibold">
                { moment(state.current_week).startOf('isoWeek').format('DD MMMM YYYY') } - { moment(state.current_week).endOf('isoWeek').format('DD MMMM YYYY') }
            </div>
        </div>
        <button type="button" class="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500" onclick="{
                    change_week.bind(this, 1)
                }">
            <span class="sr-only">Settimana successiva</span>
            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
        </button>
    </div>

    <script>
        import moment from 'moment-with-locales-es6'
        import eventBus from '/src/utils/event-bus'
        import * as events from '/src/utils/events'

        export default {

            moment: moment,

            onBeforeMount(props, state) {
                moment.locale('it')

                this.state = {
                    current_week: moment().format('YYYY-MM-DD')
                }
            },

            onMounted(props, state) {
                eventBus.trigger(events.CHANGE_WEEK, {current_week: this.state.current_week})
            },

            change_week(delta) {
                const current_week = moment(this.state.current_week).add(delta, 'w').format('YYYY-MM-DD')
                this.update({
                    current_week: current_week
                })
                eventBus.trigger(events.CHANGE_WEEK, {current_week: this.state.current_week})
            }
        }
    </script>
</choose-week>