<app>
    <router>
        <div if={state.user}>
            <!-- Utente autenticato -->
            <dashboard></dashboard>
        </div>
        <div if={!state.user}>
            <!-- L'utente non è autenticato, mostrare la pagina di login o registrazione -->
            <login></login>
        </div>
    </router>

    <script>
        import { setBase } from '@riotjs/route'
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import Parse from '/src/services/parse-init'
        import { router } from '@riotjs/route'
        import * as events from '/src/utils/events'

        setBase('/')

        export default {

            onBeforeMount() {
                this.state = {
                    user: StoreService.getUser()
                }
            },

            onMounted() {
                eventBus.on(events.LOGIN_SUCCESS, (user) => {
                    this.update({user: user})
                    const path = '/'
                    history.pushState({}, '', path)
                    router.push(path)
                })

                eventBus.on(events.LOGOUT_SUCCESS, (e) => {
                    this.update({user: null})
                })

                eventBus.on(events.GENERIC_ERROR, (e) => {
                    if(e.code === 209) {
                        eventBus.trigger(events.LOGOUT, e);
                    } else {
                        console.error(e)
                        eventBus.trigger(events.OPEN_MODAL_ERROR, {
                            ckey: "error",
                            title: "Errore generico",
                            text: e.error
                        });
                    }
                })

                document.addEventListener('click', (e) => {
                    eventBus.trigger(events.DOCUMENT_CLICK, e.target);
                });
            },

            onBeforeUnmount() {
                eventBus.off(events.LOGIN_SUCCESS)
                eventBus.off(events.LOGOUT_SUCCESS)
                eventBus.off(events.GENERIC_ERROR)
            }
        };
    </script>
</app>
