<combobox>
    <div class="{ props.buttons ? 'flex' : '' }">
        <div>
            <label for="combobox{ props.ckey }" class="block text-sm font-medium leading-6 text-gray-900">{ props.label }</label>
            <div class="relative">
                <input id="combobox{ props.ckey }" type="text" value="{ state.item_selected_value }" placeholder="{ props.placeholder }" oninput="{ filter_items }" class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" role="combobox" aria-controls="options" aria-expanded="false">
                <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none" onclick="{
                            toggle_visibility
                        }">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                </button>

                <ul if="{ state.open }" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" id="options" role="listbox">
                    <li each="{ item in state.filtered_items }" class="relative cursor-default select-none py-2 pl-3 pr-9 { item.value === state.item_highlighted ? 'text-white bg-blue-600' : 'text-gray-900' }" id="option-0" role="option" tabindex="-1" onclick="{
                                select_item.bind(this, item.value)
                            }" onmouseenter="{ highlight_item.bind(this, item.value, true) }" onmouseleave="{ highlight_item.bind(this, item.value, false) }">
                        <div class="flex">
                            <span if="{ item.sublabel }" class="truncate { item.value === state.item_highlighted ? 'text-blue-200' : 'text-gray-500' }">{ item.sublabel } -</span>
                            <span class="ml-2 truncate { item.value === state.item_selected ? 'font-semibold' : '' }">{ item.label }</span>
                        </div>

                        <span if="{ item.value === state.item_selected }" class="absolute inset-y-0 right-0 flex items-center pr-4 { item.value === state.item_highlighted ? 'text-white bg-blue-600' : 'text-gray-900' }">
                            <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                            </svg>
                        </span>
                    </li>
                </ul>
            </div>
            <input type="hidden" name="{ props.ckey }" value="{ state.item_selected }" />
        </div>
        <div if="{ props.buttons }">
            <button onclick="{
                        reset
                    }" class="ml-3 inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600">Reset</button>
        </div>
    </div>


    <script>
        import eventBus from '/src/utils/event-bus'
        import * as events from '/src/utils/events'

        export default {

            onBeforeMount(props, state) {
                this.state = {
                    open: false,
                    items: [],
                    depending_items: [],
                    filtered_items: [],
                    item_selected: '',
                    item_highlighted: '',
                    item_selected_value: ''
                }

                eventBus.on(events.UPDATE_COMBOBOX, this.handleUpdateCombobox)
                eventBus.on(events.SELECT_COMBOBOX, this.handleSelectCombobox)
                eventBus.on(events.SELECTED_COMBOBOX, this.handleSelectedCombobox)
                eventBus.on(events.DOCUMENT_CLICK, this.handleDocumentClick)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.UPDATE_COMBOBOX, this.handleUpdateCombobox)
                eventBus.off(events.SELECT_COMBOBOX, this.handleSelectCombobox)
                eventBus.off(events.SELECTED_COMBOBOX, this.handleSelectedCombobox)
                eventBus.off(events.DOCUMENT_CLICK, this.handleDocumentClick)
            },

            handleDocumentClick(clickedElement) {
                if (!this.root.contains(clickedElement)) {
                    this.update({
                        open: false
                    });
                }
            },

            handleUpdateCombobox(e) {
                if (e.ckey === this.props.ckey) {
                    if (this.props.depends_on) {
                        this.update({
                            items: e.items,
                            depending_items: [],
                            filtered_items: []
                        })
                    } else {
                        this.update({
                            items: e.items,
                            depending_items: e.items,
                            filtered_items: e.items
                        })
                    }
                }
            },

            handleSelectCombobox(e) {
                if (e.ckey === this.props.ckey) {
                    this.select_item(e.item)
                }
            },

            handleSelectedCombobox(e) {
                if (e.ckey === this.props.depends_on) {
                    const items = this.state.items.filter(
                            item => item.depending === e.value
                    )
                    this.update({
                        depending_items: items,
                        filtered_items: items,
                        item_selected: '',
                        item_selected_value: ''
                    })
                }
            },

            toggle_visibility() {
                this.update({
                    open: !this.state.open
                })
            },

            select_item(v) {
                const item = this.state.items.find(item => item.value === v);
                this.update({
                    item_selected: v,
                    item_selected_value: item.label,
                    open: false
                })
                this.fire_select_item()
            },

            highlight_item(v, highlighted) {
                const item = this.state.items.find(item => item.value === v);
                this.update({
                    item_highlighted: v
                })
            },

            filter_items(e) {
                const filterText = e.target.value;
                this.update({
                    item_selected_value: filterText,
                    open: true,
                    filtered_items: this.state.depending_items.filter(item =>
                        item.label.toLowerCase().includes(filterText.toLowerCase())
                    )
                });
            },

            fire_select_item() {
                eventBus.trigger(events.SELECTED_COMBOBOX, {
                    ckey: this.props.ckey,
                    value: this.state.item_selected
                })
            },

            reset() {
                this.update({
                    open: false,
                    item_selected: '',
                    item_highlighted: '',
                    item_selected_value: ''
                })
                
                eventBus.trigger(events.SELECTED_COMBOBOX, {
                    ckey: this.props.ckey,
                    value: this.state.item_selected
                })
            }
        }
    </script>
</combobox>