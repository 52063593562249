<anagrafica-atleti>
    <div>
        <div>
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="/{ ckey }" text="Anagrafica atleti" />
            </breadcrumb>
        </div>
        <heading-page ckey="{ ckey }" text="Anagrafica atleti" link="/{ ckey }/create" search="1" refresh="1"></heading-page>
        <generic-table ckey='{ ckey }' th_array='{ get_th_array() }' td_array='{ get_td_array() }' title_delete="{ getTitleModalDelete.bind(this) }" text_delete="{ getTextModalDelete.bind(this) }"></generic-table>
    </div>

    <script>
        import eventBus from '/src/utils/event-bus'
        import { KEY_ATLETA } from '/src/utils/constants'
        import * as events from '/src/utils/events'

        export default {

            ckey: KEY_ATLETA,

            get_th_array() {
                const th_array = []
                th_array.push({
                    column: 'fname',
                    label: 'Nome',
                    sorting: true
                })
                th_array.push({
                    column: 'lname',
                    label: 'Cognome',
                    sorting: true
                })
                th_array.push({
                    column: 'email',
                    label: 'Email',
                    sorting: true,
                    hidesm: true
                })
                return th_array
            },

            get_td_array() {
                const td_array = []
                td_array.push({
                    field: 'fname',
                    highlight: false
                })
                td_array.push({
                    field: 'lname',
                    highlight: true
                })
                td_array.push({
                    field: 'email',
                    highlight: false,
                    hidesm: true
                })
                return td_array
            },

            onBeforeMount(props, state) {
                this.state = {
                    items: {
                        count: 0,
                        results: []
                    }
                }

                eventBus.on(events.ITEMS_CHANGED, this.handleTableItemsChanged)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.ITEMS_CHANGED, this.handleTableItemsChanged)
            },

            handleTableItemsChanged(e) {
                if (e.ckey === this.ckey) {
                    this.update({
                        items: e.items
                    })
                }
            },

            getTitleModalDelete(e) {
                if (e.ckey === this.ckey) {
                    return "Eliminazione atleta"
                }
            },

            getTextModalDelete(e) {
                if (e.ckey === this.ckey) {
                    const atleta = this.state.items.results.find(item => item.id === e.item_id)
                    const nome = atleta ? `${atleta.get('fname')} ${atleta.get('lname')}` : ''
                    return "Stai per cancellare l'atleta " + nome + ". L'azione è irreversibile. Vuoi continuare?"
                }
            }
        }

    </script>
</anagrafica-atleti>
