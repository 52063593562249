const _temp0 = require("../components/global/anagrafica-atleti.riot");
const _temp1 = require("../components/global/anagrafica-attrezzi.riot");
const _temp2 = require("../components/global/anagrafica-categorie.riot");
const _temp3 = require("../components/global/anagrafica-esercizi.riot");
const _temp4 = require("../components/global/breadcrumb-item.riot");
const _temp5 = require("../components/global/breadcrumb.riot");
const _temp6 = require("../components/global/button-back.riot");
const _temp7 = require("../components/global/button-submit.riot");
const _temp8 = require("../components/global/choose-week.riot");
const _temp9 = require("../components/global/combobox.riot");
const _temp10 = require("../components/global/dashboard.riot");
const _temp11 = require("../components/global/edit-atleta.riot");
const _temp12 = require("../components/global/edit-attrezzo.riot");
const _temp13 = require("../components/global/edit-categoria.riot");
const _temp14 = require("../components/global/edit-esercizio.riot");
const _temp15 = require("../components/global/esercizi-atleta.riot");
const _temp16 = require("../components/global/esercizi.riot");
const _temp17 = require("../components/global/generic-table.riot");
const _temp18 = require("../components/global/heading-page.riot");
const _temp19 = require("../components/global/input-file.riot");
const _temp20 = require("../components/global/input-text.riot");
const _temp21 = require("../components/global/item-nav.riot");
const _temp22 = require("../components/global/login.riot");
const _temp23 = require("../components/global/logout-button.riot");
const _temp24 = require("../components/global/modal-add-esecuzione.riot");
const _temp25 = require("../components/global/modal-delete.riot");
const _temp26 = require("../components/global/modal-error.riot");
const _temp27 = require("../components/global/pagination.riot");
const _temp28 = require("../components/global/raw.riot");
const _temp29 = require("../components/global/table-td.riot");
const _temp30 = require("../components/global/table-th.riot");
module.exports = {
  "anagrafica-atleti": _temp0,
  "anagrafica-attrezzi": _temp1,
  "anagrafica-categorie": _temp2,
  "anagrafica-esercizi": _temp3,
  "breadcrumb-item": _temp4,
  "breadcrumb": _temp5,
  "button-back": _temp6,
  "button-submit": _temp7,
  "choose-week": _temp8,
  "combobox": _temp9,
  "dashboard": _temp10,
  "edit-atleta": _temp11,
  "edit-attrezzo": _temp12,
  "edit-categoria": _temp13,
  "edit-esercizio": _temp14,
  "esercizi-atleta": _temp15,
  "esercizi": _temp16,
  "generic-table": _temp17,
  "heading-page": _temp18,
  "input-file": _temp19,
  "input-text": _temp20,
  "item-nav": _temp21,
  "login": _temp22,
  "logout-button": _temp23,
  "modal-add-esecuzione": _temp24,
  "modal-delete": _temp25,
  "modal-error": _temp26,
  "pagination": _temp27,
  "raw": _temp28,
  "table-td": _temp29,
  "table-th": _temp30
}