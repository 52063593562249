import Parse from './parse-init'
import * as constants from '/src/utils/constants'
import moment from 'moment-with-locales-es6'
        ;
const Attrezzo = Parse.Object.extend("Attrezzo")
const Categoria = Parse.Object.extend("Categoria")
const Esercizio = Parse.Object.extend("Esercizio")
const Esecuzione = Parse.Object.extend("Esecuzione")

function _applySorting(query, sortColumn, sortDirection) {
    if ('asc' === sortDirection) {
        query.addAscending(sortColumn);
    } else if ('desc' === sortDirection) {
        query.addDescending(sortColumn);
    }
}

async function _getItems(Cl, defaultSortingField, sortColumn, sortDirection, page, search_text, include) {
    try {
        const query = new Parse.Query(Cl);
        query.notEqualTo('deleted', true);
        if (include) {
            query.include(include)
        }
        _applySorting(query, sortColumn || defaultSortingField, sortDirection)

        if (search_text) {
            query.equalTo('search_array', search_text)
        }
        let results = null;
        if (page > 0) {
            query.limit(constants.N_ITEMS)
            query.skip((page - 1) * constants.N_ITEMS)
            query.withCount()
            results = await query.find()
        } else {
            results = await _findAll(query)
        }
        return {items: results}
    } catch (e) {
        return {
            items: null,
            code: e.code,
            error: e.message
        }
    }
}

async function _getItem(Cl, item_id) {
    try {
        const query = new Parse.Query(Cl);
        const item = await query.get(item_id);
        return item;
    } catch (error) {
        console.error("Errore nel recupero dell'item:", error);
        return null;
    }
}

async function _deleteItem(Cl, item_id) {
    try {
        const item = Cl.createWithoutData(item_id);
        item.set("deleted", true);
        await item.save();
        return item_id;
    } catch (error) {
        console.error("Errore nel recupero dell'item:", error);
        return null;
    }
}

async function _findAll(query, dir, criteria) {
    let objects = [];
    let o = [];
    let n = 0;
    const limit = 1000;
    query.limit(limit);
    if (dir && dir < 0) {
        if (criteria) {
            query.descending(criteria);
        } else {
            query.descending("createdAt");
        }
    } else {
        if (criteria) {
            query.ascending(criteria);
        } else {
            query.ascending("createdAt");
        }
    }
    do {
        o = await query.find();
        objects = objects.concat(o);
        n++;
        query.skip(n * limit);
    } while (o.length === limit);
    return objects;
}

function _normalizeData(mapDate) {
    let dataNormalizzata = [];

    // Itera su ogni voce della mappa
    for (let [dataEsecuzione, mapAtleti] of mapDate.entries()) {
        let atleti = [];
        for (let [atletaId, atletaInfo] of mapAtleti.entries()) {
            let attrezzi = [];
            for (let [attrezzoId, punteggi] of atletaInfo.attrezzi.entries()) {
                // Costruisci l'array degli attrezzi per ogni atleta
                attrezzi.push({
                    attrezzo_id: attrezzoId,
                    ...punteggi // Questo include parte e intero
                });
            }

            // Costruisci l'array degli atleti
            atleti.push({
                atleta_id: atletaId,
                atleta_name: atletaInfo.name,
                attrezzi: attrezzi
                        // Nota: qui potresti aggiungere altre informazioni sull'atleta se disponibili
            });
        }

        // Ordina gli atleti per ID (se necessario)
        atleti.sort((a, b) => a.atleta_name.localeCompare(b.atleta_name));

        // Aggiungi la voce per la data corrente all'array normalizzato
        dataNormalizzata.push({
            data_esecuzione: dataEsecuzione,
            atleti: atleti
        });
    }

    // Ordina l'array normalizzato per data di esecuzione, mettendo la "somma" alla fine
    dataNormalizzata.sort((a, b) => {
        if (a.data_esecuzione === 'somma')
            return 1;
        if (b.data_esecuzione === 'somma')
            return -1;
        return a.data_esecuzione.localeCompare(b.data_esecuzione);
    });

    return dataNormalizzata;
}

const StoreService = {

    async getItems(key, sortColumn, sortDirection, page, search_text, include) {
        let Cl = null
        let defaultField = null
        let data = []
        switch (key) {
            case constants.KEY_ATLETA:
                const params = {
                    sortColumn: sortColumn,
                    sortDirection: sortDirection,
                    page: page,
                    searchText: search_text,
                    nItems: constants.N_ITEMS
                };
                data = await Parse.Cloud.run('getAtleti', params);
                if (data) {
                    data = {items: data}
                }
                break
            case constants.KEY_ATTREZZO:
                Cl = Attrezzo
                defaultField = 'descrizione'
                data = await _getItems(Cl, defaultField, sortColumn, sortDirection, page, search_text, include)
                break
            case constants.KEY_CATEGORIA:
                Cl = Categoria
                defaultField = 'descrizione'
                data = await _getItems(Cl, defaultField, sortColumn, sortDirection, page, search_text, include)
                break
            case constants.KEY_ESERCIZIO:
                Cl = Esercizio
                defaultField = 'descrizione'
                data = await _getItems(Cl, defaultField, sortColumn, sortDirection, page, search_text, include)
                break
        }
        return data
    },

    async getItem(key, item_id) {
        let Cl = null
        let item = null
        switch (key) {
            case constants.KEY_ATLETA:
                Cl = Parse.User
                item = await _getItem(Cl, item_id)
                break
            case constants.KEY_ATTREZZO:
                Cl = Attrezzo
                item = await _getItem(Cl, item_id)
                break
            case constants.KEY_CATEGORIA:
                Cl = Categoria
                item = await _getItem(Cl, item_id)
                break
            case constants.KEY_ESERCIZIO:
                const queryEsercizio = new Parse.Query(Esercizio)
                queryEsercizio.include("categoria")
                item = await queryEsercizio.get(item_id)
                break
        }

        return item
    },

    async deleteItem(key, item_id) {
        let Cl = null
        switch (key) {
            case constants.KEY_ATLETA:
                const data = {
                    userId: item_id,
                };
                await Parse.Cloud.run('deleteUser', data);
                break
            case constants.KEY_ATTREZZO:
                Cl = Attrezzo
                await _deleteItem(Cl, item_id)
                break
            case constants.KEY_CATEGORIA:
                Cl = Categoria
                await _deleteItem(Cl, item_id)
            case constants.KEY_ESERCIZIO:
                Cl = Esercizio
                await _deleteItem(Cl, item_id)
                break
            case constants.KEY_ESECUZIONE:
                Cl = Esecuzione
                await _deleteItem(Cl, item_id)
                break
        }
        return item_id;
    },

    getUser() {
        let user = null;
        try {
            user = Parse.User.current();
        } catch (e) {
            console.error(e);
        }
        return user;
    },

    async login(username, password) {
        try {
            const user = await Parse.User.logIn(username, password, {usePost: false});
            return {
                user: user
            }
        } catch (e) {
            return {
                error: e
            }
        }
    },

    async logout() {
        try {
            await Parse.User.logOut()
            return {
                logout: true
            }
        } catch (e) {
            return {
                error: e.message
            }
        }
    },

    async saveAtleta(item_id, fname, lname, email, password) {
        const data = {
            userId: item_id,
            fname: fname,
            lname: lname,
            email: email,
            password: password,
        }
        try {
            const atleta = await Parse.Cloud.run('saveUser', data)
            return {item: atleta}
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error)
            return {
                item: null,
                error: error.message
            }
        }
    },

    async saveAttrezzo(item_id, descrizione, file) {
        try {
            let item = null
            if (item_id) {
                item = Attrezzo.createWithoutData(item_id)
            } else {
                item = new Attrezzo()
            }
            item.set('descrizione', descrizione)
            if (file && file.includes('base64,')) {
                const base64Data = file.split('base64,')[1]
                const parseFile = new Parse.File('icona.png', {base64: base64Data})
                await parseFile.save()
                item.set('icona', parseFile)
            } else if (!file) {
                item.unset('icona')
            }
            await item.save()
            return {item: item}
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error)
            return {
                item: null,
                error: error.message
            }
        }
    },

    async saveCategoria(item_id, descrizione, attrezzo_id) {
        if (!attrezzo_id) {
            console.error("Errore nel salvataggio dell'item: Attrezzo obbligatorio")
            return {
                item: null,
                error: "Attrezzo obbligatorio"
            }
        }
        if (!descrizione) {
            console.error("Errore nel salvataggio dell'item: Descrizione obbligatoria")
            return {
                item: null,
                error: "Descrizione obbligatoria"
            }
        }
        try {
            let item = null
            if (item_id) {
                item = Categoria.createWithoutData(item_id)
            } else {
                item = new Categoria()
            }
            item.set('descrizione', descrizione)
            const attrezzo = Attrezzo.createWithoutData(attrezzo_id)
            item.set('attrezzo', attrezzo);
            await item.save()
            return {item: item}
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error)
            return {
                item: null,
                error: error.message
            }
        }
    },

    async saveEsercizio(item_id, descrizione, punteggio, tipologia, categoria_id) {
        if (!categoria_id) {
            console.error("Errore nel salvataggio dell'item: Categoria obbligatoria")
            return {
                item: null,
                error: "Categoria obbligatoria"
            }
        }
        if (!descrizione) {
            console.error("Errore nel salvataggio dell'item: Descrizione obbligatoria")
            return {
                item: null,
                error: "Descrizione obbligatoria"
            }
        }
        if (!punteggio) {
            console.error("Errore nel salvataggio dell'item: Punteggio obbligatorio")
            return {
                item: null,
                error: "Punteggio obbligatoria"
            }
        }
        if (!tipologia) {
            console.error("Errore nel salvataggio dell'item: Tipologia obbligatoria")
            return {
                item: null,
                error: "Tipologia obbligatoria"
            }
        }
        try {
            let item = null
            if (item_id) {
                item = Esercizio.createWithoutData(item_id)
            } else {
                item = new Esercizio()
            }
            item.set('descrizione', descrizione)
            item.set('tipologia', tipologia)
            item.set('punteggio', parseFloat(punteggio))
            const categoria = Categoria.createWithoutData(categoria_id)
            item.set('categoria', categoria)
            await item.save()
            return {item: item}
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error)
            return {
                item: null,
                error: error.message
            }
        }
    },

    async saveEsecuzione(item_id, esercizio_id, atleta_id, data_esecuzione) {
        try {
            let item = null;
            if (item_id) {
                item = Esecuzione.createWithoutData(item_id)
            } else {
                item = new Esecuzione()
            }
            if (esercizio_id && atleta_id) {
                item.set('dataEsecuzione', data_esecuzione)
                const esercizio = Esercizio.createWithoutData(esercizio_id)
                item.set('esercizio', esercizio)
                const atleta = Parse.User.createWithoutData(atleta_id)
                item.set('atleta', atleta)
                await item.save()
            }
            return item;
        } catch (error) {
            console.error("Errore nel salvataggio dell'item:", error)
            return null;
        }
    },

    async getEsecuzioni(start_date, end_date, atleta_id) {
        try {
            const result = await _getItems(Attrezzo)
            if (result.items) {
                const query = new Parse.Query(Esecuzione)
                query.include(['atleta', 'esercizio', 'esercizio.categoria', 'esercizio.categoria.attrezzo'])
                if (atleta_id) {
                    const atleta = Parse.User.createWithoutData(atleta_id)
                    query.equalTo('atleta', atleta)
                }
                query.greaterThanOrEqualTo('dataEsecuzione', start_date)
                query.lessThanOrEqualTo('dataEsecuzione', end_date)
                query.notEqualTo('deleted', true)
                const esecuzioni = await _findAll(query, 'dataEsecuzione')

                let giorno = start_date
                let mapAtleti, mapAtletiSomma, mapAttrezzi, mapAttrezziSomma, punteggi = null

                const mapDate = new Map()
                mapAtletiSomma = new Map()
                mapDate.set(constants.SOMMA, mapAtletiSomma)
                do {
                    mapAtleti = new Map()
                    mapDate.set(giorno, mapAtleti)
                    esecuzioni.forEach(esecuzione => {
                        const dataEsecuzione = esecuzione.get("dataEsecuzione")
                        if (dataEsecuzione === giorno) {
                            const atleta = esecuzione.get("atleta")
                            const atletaName = atleta.get("lname") + "<br />" + atleta.get("fname");
                            const attrezzo = esecuzione.get("esercizio").get("categoria").get("attrezzo")
                            const tipologia = esecuzione.get("esercizio").get("tipologia")
                            const punteggio = esecuzione.get("esercizio").get("punteggio")

                            mapAtleti = mapDate.get(giorno)
                            if (!mapAtleti.has(atleta.id)) {
                                mapAttrezzi = new Map()
                                result.items.forEach(a => {
                                    mapAttrezzi.set(a.id, {
                                        parte: 0,
                                        intero: 0
                                    })
                                })
                                mapAtleti.set(atleta.id, {name: atletaName, attrezzi: mapAttrezzi})
                            }
                            mapAttrezzi = mapAtleti.get(atleta.id).attrezzi
                            punteggi = mapAttrezzi.get(attrezzo.id)
                            punteggi[tipologia] += punteggio

                            mapAtletiSomma = mapDate.get(constants.SOMMA)
                            if (!mapAtletiSomma.has(atleta.id)) {
                                mapAttrezziSomma = new Map()
                                result.items.forEach(a => {
                                    mapAttrezziSomma.set(a.id, {
                                        parte: 0,
                                        intero: 0
                                    })
                                })
                                mapAtletiSomma.set(atleta.id, {name: atletaName, attrezzi: mapAttrezziSomma})
                            }
                            mapAttrezziSomma = mapAtletiSomma.get(atleta.id).attrezzi
                            punteggi = mapAttrezziSomma.get(attrezzo.id)
                            punteggi[tipologia] += punteggio
                        }
                    })

                    giorno = moment(giorno).add(1, 'd').format('YYYY-MM-DD')
                } while (moment(giorno).isSameOrBefore(moment(end_date)))

                const data = _normalizeData(mapDate)
                return data
            } else {
                return null
            }
        } catch (error) {
            console.error("Errore nel recupero degli esercizi:", error);
            return null;
        }
    },

    async getEsecuzioniDettaglio(date, atleta_id) {
        const query = new Parse.Query(Esecuzione)
        query.include('esercizio')
        const atleta = Parse.User.createWithoutData(atleta_id)
        query.equalTo('atleta', atleta)
        query.equalTo('dataEsecuzione', date)
        query.notEqualTo('deleted', true)
        const esecuzioni = await _findAll(query)
        esecuzioni.sort((a, b) => {
            // Ottieni le descrizioni degli attrezzi attraverso il puntatore e il metodo get
            const attrezzoA = a.get("esercizio").get("attrezzoDescrizione").toUpperCase();
            const attrezzoB = b.get("esercizio").get("attrezzoDescrizione").toUpperCase();

            if (attrezzoA < attrezzoB)
                return -1;
            if (attrezzoA > attrezzoB)
                return 1;

            // Se le descrizioni degli attrezzi sono uguali, confronta le date di creazione
            const esercizioA = a.createdAt;
            const esercizioB = b.createdAt;

            // ordinamento DESC
            if (esercizioA < esercizioB)
                return 1;
            if (esercizioA > esercizioB)
                return -1;

            return 0; // Se entrambe le descrizioni delle categorie e degli esercizi sono uguali
        });
        return esecuzioni
    }
};
export default StoreService;