<input-file>
    <div class="col-span-full">
        <label for="{ props.ckey.replace('/', '__') }" class="block text-sm font-medium leading-6 text-gray-900">{ props.label }</label>
        <div class="mt-2 flex items-center gap-x-3">
            <svg if="{ state.url === '' }" class="h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
            <img class="w-16" if="{ state.url }" src="{ state.url  }" />
            <input type="file" id="{ props.ckey.replace('/', '__') }" class="hidden" accept="image/*" onchange="{
                        handleFileChange
                    }">
            <button type="button" onclick="{
                        triggerFileInput
                    }" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Cambia</button>
            <button if="{ state.url }" type="button" onclick="{
                        deleteFile
                    }" class="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white hover:bg-red-500 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Elimina</button>
        </div>
    </div>

    <script>
        import eventBus from '/src/utils/event-bus'
        import * as events from '/src/utils/events'

        export default {

            onBeforeMount(props, state) {
                this.state = {
                    url: ''
                }

                eventBus.on(events.UPDATE_FILE, this.handleUpdateFile)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.UPDATE_FILE, this.handleUpdateFile)
            },

            handleUpdateFile(e) {
                if (e.ckey === this.props.ckey) {
                    this.update({
                        url: e.url
                    });
                }
            },

            triggerFileInput() {
                this.$('#' + this.props.ckey.replace('/', '__')).click();
            },

            handleFileChange(e) {
                const file = e.target.files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.update({url: e.target.result});
                        eventBus.trigger(events.UPDATED_FILE, {
                            ckey: this.props.ckey,
                            url: e.target.result
                        })
                    }
                    reader.readAsDataURL(file)
                }
            },

            deleteFile(e) {
                this.update({url: ''});
                eventBus.trigger(events.UPDATED_FILE, {
                    ckey: this.props.ckey,
                    url: ''
                })
            }
        }
    </script>
</input-file>
