<heading-page>
    <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
            <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">{ state.text }</h2>
        </div>
        <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
            <div if="{ props.search }" class="w-full max-w-lg lg:max-w-xs">
                <form onsubmit="{
                            search
                        }">
                    <label for="search" class="sr-only">Cerca</label>
                    <div class="flex">
                        <div class="relative mr-1">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input id="search" name="search" class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" placeholder="Cerca" type="search" oninput="{ edit }" value="{ state.search_text }">
                        </div>
                        <button class="mr-1 md:ml-3 md:mr-2 inline-flex items-center rounded-md bg-cyan-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600">Cerca</button>
                    </div>
                </form>
            </div>

            <a if="{ props.link }" href="{ props.link }" role="button" class="mx-1 md:ml-3 md:mr-2 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Nuovo</a>

            <button if="{ props.refresh }" type="button" class="btn-round-edit" onclick="{
                        refresh
                    }">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd" d="M15.312 11.424a5.5 5.5 0 0 1-9.201 2.466l-.312-.311h2.433a.75.75 0 0 0 0-1.5H3.989a.75.75 0 0 0-.75.75v4.242a.75.75 0 0 0 1.5 0v-2.43l.31.31a7 7 0 0 0 11.712-3.138.75.75 0 0 0-1.449-.39Zm1.23-3.723a.75.75 0 0 0 .219-.53V2.929a.75.75 0 0 0-1.5 0V5.36l-.31-.31A7 7 0 0 0 3.239 8.188a.75.75 0 1 0 1.448.389A5.5 5.5 0 0 1 13.89 6.11l.311.31h-2.432a.75.75 0 0 0 0 1.5h4.243a.75.75 0 0 0 .53-.219Z" clip-rule="evenodd" />
                </svg>
            </button>

            <slot />
        </div>
    </div>

    <script>
        import eventBus from '/src/utils/event-bus'
        import * as events from '/src/utils/events'

        export default {

            onBeforeMount(props, state) {
                this.state = {
                    text: props.text,
                    search_text: ''
                }

                eventBus.on(events.UPDATE_HEADING, this.handleUpdateHeading)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.UPDATE_HEADING, this.handleUpdateHeading)
            },

            edit(e) {
                this.update({
                    search_text: e.target.value.toLowerCase()
                })
            },

            handleUpdateHeading(e) {
                if (e.ckey === this.props.ckey) {
                    this.update({
                        text: e.text
                    });
                }
            },

            search(e) {
                e.preventDefault()
                eventBus.trigger(events.SEARCH, {
                    ckey: this.props.ckey,
                    search_text: this.state.search_text
                })
            },

            refresh(e) {
                eventBus.trigger(events.REFRESH, {
                    ckey: this.props.ckey
                })
            }
        }

    </script>
</heading-page>