<edit-esercizio>
    <div>
        <div>
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="/{ ckey }" text="Anagrafica esercizi" />
                <breadcrumb-item ckey="{ ckey }/id" link="" text="Crea esercizio" />
            </breadcrumb>
        </div>
        <heading-page ckey="{ ckey }/id" text="Crea esercizio" link="/{ ckey }/create" />
        <div class='mt-8'>
            <form method="POST" onsubmit="{
                        submit
                    }">
                <input type="hidden" name="item_id" value="{ state.item_id }" />
                <div class="space-y-12">
                    <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <h2 class="text-base font-semibold leading-7 text-gray-900">Informazioni generali.</h2>
                            <p class="mt-1 text-sm leading-6 text-gray-600">Inserisci i dati dell'esercizio.</p>
                        </div>
                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <div class="col-span-full">
                                <input-text ckey="descrizione" label="Nome dell'esercizio" value="{ state.descrizione }" oninput="{ edit }" required="required"></input-text>
                            </div>
                            <div class="sm:col-span-3">
                                <input-text ckey="punteggio" inputtype="number" step=".01" label="Punteggio" value="{ state.punteggio }" oninput="{ edit }" required="required"></input-text>
                            </div>
                            <div class="sm:col-span-3">
                                <combobox ckey="tipologia" label="Tipologia"></combobox>
                            </div>
                            <div class="sm:col-span-3">
                                <combobox ckey="attrezzo" label="Attrezzo"></combobox>
                            </div>
                            <div class="sm:col-span-3">
                                <combobox ckey="categoria" label="Categoria" depends_on="attrezzo"></combobox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button-back text="Indietro"></button-back>
                    <button-submit text="Salva"></button-submit>
                </div>
            </form>
        </div>
    </div>

    <script>
        import StoreService from '/src/services/store';
        import eventBus from '/src/utils/event-bus';
        import { router } from '@riotjs/route'
        import { KEY_ATTREZZO, KEY_CATEGORIA, KEY_ESERCIZIO } from '/src/utils/constants'
        import * as events from '/src/utils/events'

        export default {

            ckey: KEY_ESERCIZIO,

            onBeforeMount(props, state) {
                this.state = {
                    item_id: '',
                    descrizione: '',
                    punteggio: '',
                    tipologia: '',
                    attrezzo_id: '',
                    categoria_id: '',
                    attrezzi: [],
                    categorie: []
                }
                
                eventBus.on(events.SELECTED_COMBOBOX, this.handleSelectedCombobox)
            },

            async onMounted(props, state) {

                const resultAttrezzi = await StoreService.getItems(KEY_ATTREZZO, 'descrizione', 'asc', 0, '')
                const resultCategorie = await StoreService.getItems(KEY_CATEGORIA, 'descrizione', 'asc', 0, '')

                if (resultAttrezzi.items && resultCategorie.items) {
                    this.update({
                        attrezzi: resultAttrezzi.items,
                        categorie: resultCategorie.items
                    })

                    const tipologia_items = [
                        {
                            value: 'parte',
                            label: 'PARTE'
                        },
                        {
                            value: 'intero',
                            label: 'INTERO'
                        }
                    ]
                    eventBus.trigger(events.UPDATE_COMBOBOX, {
                        ckey: 'tipologia',
                        items: tipologia_items
                    })

                    const attrezzi_items = []
                    for (let i = 0;
                    i < this.state.attrezzi.length; i++) {
                        attrezzi_items.push({
                            value: this.state.attrezzi[i].id,
                            label: this.state.attrezzi[i].get('descrizione')
                        })
                    }
                    eventBus.trigger(events.UPDATE_COMBOBOX, {
                        ckey: KEY_ATTREZZO,
                        items: attrezzi_items
                    })

                    const categorie_items = []
                    for (let i = 0; i < this.state.categorie.length; i++) {
                        categorie_items.push({
                            value: this.state.categorie[i].id,
                            label: this.state.categorie[i].get('descrizione'),
                            depending: this.state.categorie[i].get('attrezzo') ? this.state.categorie[i].get('attrezzo').id : ''
                        })
                    }
                    eventBus.trigger(events.UPDATE_COMBOBOX, {
                        ckey: KEY_CATEGORIA,
                        items: categorie_items
                    })

                    if (props.item) {
                        const item = await StoreService.getItem(this.ckey, props.item)
                        this.update({
                            item_id: item.id,
                            descrizione: item.get('descrizione'),
                            punteggio: item.get('punteggio'),
                            tipologia: item.get('tipologia'),
                            categoria_id: item.get('categoria') ? item.get('categoria').id : '',
                            attrezzo_id: item.get('categoria') ? item.get('categoria').get('attrezzo') ? item.get('categoria').get('attrezzo').id : '' : ''
                        })
                        // Aggiorna il breadcrumb con il nome dell'esercizio
                        eventBus.trigger(events.UPDATE_BREADCRUMB, {
                            ckey: this.ckey + '/id',
                            text: `Modifica esercizio ${item.get('descrizione')}`
                        })
                        // Aggiorna la testata con il nome dell'esercizio
                        eventBus.trigger(events.UPDATE_HEADING, {
                            ckey: this.ckey + '/id',
                            text: `Modifica esercizio ${item.get('descrizione')}`
                        })
                        // Aggiorna l'input file
                        eventBus.trigger(events.UPDATE_FILE, {
                            ckey: this.ckey + '/icona',
                            url: this.state.icona
                        })
                        eventBus.trigger(events.SELECT_COMBOBOX, {
                            ckey: 'tipologia',
                            item: this.state.tipologia
                        })
                        eventBus.trigger(events.SELECT_COMBOBOX, {
                            ckey: KEY_ATTREZZO,
                            item: this.state.attrezzo_id
                        })
                        eventBus.trigger(events.SELECT_COMBOBOX, {
                            ckey: KEY_CATEGORIA,
                            item: this.state.categoria_id
                        })
                    } else {
                        this.update({
                            item_id: '',
                            descrizione: '',
                            punteggio: '',
                            tipologia: '',
                            attrezzo_id: '',
                            categoria_id: '',
                        })
                    }
                } else {
                    if (resultAttrezzi.error) {
                        eventBus.trigger(events.GENERIC_ERROR, {
                            ckey: this.ckey,
                            code: resultAttrezzi.code,
                            error: resultAttrezzi.error
                        })
                    } else if (resultCategorie.error) {
                        eventBus.trigger(events.GENERIC_ERROR, {
                            ckey: this.ckey,
                            code: resultCategorie.code,
                            error: resultCategorie.error
                        })
                    }
                }
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.SELECTED_COMBOBOX, this.handleSelectedCombobox)
            },
            
            handleSelectedCombobox(e) {
                switch(e.ckey) {
                    case 'tipologia':
                        this.update({
                            tipologia: e.value
                        })
                        break;
                    case KEY_ATTREZZO:
                        this.update({
                            attrezzo_id: e.value
                        })
                        break;
                    case KEY_CATEGORIA:
                        this.update({
                            categoria_id: e.value
                        })
                        break;
                }
            },

            edit(e) {
                this.update({
                    [e.target.id]: e.target.value
                })
            },

            async submit(e) {
                e.preventDefault()
                const result = await StoreService.saveEsercizio(this.state.item_id, this.state.descrizione, this.state.punteggio, this.state.tipologia, this.state.categoria_id)
                if (result.item) {
                    const path = '/' + this.ckey
                    history.pushState({}, '', path)
                    router.push(path)
                } else {
                    eventBus.trigger(events.OPEN_MODAL_ERROR, {
                        ckey: "error",
                        title: "Errore nel salvataggio dell'esercizio",
                        text: result.error
                    })
                }
            }
        }
    </script>
</edit-esercizio>
