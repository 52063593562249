/* Riot WIP, @license MIT */
const EACH = 0;
const IF = 1;
const SIMPLE = 2;
const TAG = 3;
const SLOT = 4;

const bindingTypes = {
  EACH,
  IF,
  SIMPLE,
  TAG,
  SLOT,
};

export { EACH, IF, SIMPLE, SLOT, TAG, bindingTypes as default };
