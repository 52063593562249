export const CHANGE_WEEK = 'change_week'
export const DELETE = 'delete'
export const DOCUMENT_CLICK = 'document_click'
export const GENERIC_ERROR = 'generic_error'
export const ITEMS_CHANGED = 'items_changed'
export const LOGIN_FAILED = 'login_failed'
export const LOGIN_SUCCESS = 'login_success'
export const LOGOUT = 'logout'
export const LOGOUT_FAILED = 'logout_failed'
export const LOGOUT_SUCCESS = 'logout_success'
export const OPEN_MODAL_ADD_ESECUZIONE = 'open_modal_add_esecuzione'
export const OPEN_MODAL_ERROR = 'open_modal_error'
export const OPEN_MODAL_DELETE = 'open_modal_delete'
export const PAGINATION_CHANGE = 'pagination_change'
export const REFRESH = 'refresh'
export const SAVE = 'save'
export const SEARCH = 'search'
export const SELECT_COMBOBOX = 'select_combobox'
export const SELECTED_COMBOBOX = 'selected_combobox'
export const SORTING_CHANGE = 'sorting_change'
export const UPDATE_BREADCRUMB = 'update_breadcrumb'
export const UPDATE_COMBOBOX = 'update_combobox'
export const UPDATE_FILE = 'update_file'
export const UPDATE_HEADING = 'update_heading'
export const UPDATE_NAV = 'update_nav'
export const UPDATED_FILE = 'updated_file'
