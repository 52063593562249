/* Riot v9.1.5, @license MIT */
import { DOMattributesToObject } from '../dependencies/@riotjs/util/dom.js';
import { callOrAssign } from '../dependencies/@riotjs/util/functions.js';

/**
 * Evaluate the component properties either from its real attributes or from its initial user properties
 * @param   {HTMLElement} element - component root
 * @param   {Object}  initialProps - initial props
 * @returns {Object} component props key value pairs
 */
function computeInitialProps(element, initialProps = {}) {
  return {
    ...DOMattributesToObject(element),
    ...callOrAssign(initialProps),
  }
}

export { computeInitialProps };
