<login>
    <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-16 w-auto" src="logo.png" alt="Ginnastica Tritium">
            <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Registro degli allenamenti</h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form class="space-y-6" method="POST" onsubmit="{
                        submit
                    }">
                <div>
                    <label for="username" class="block text-sm font-medium leading-6 text-gray-900">Username</label>
                    <div class="mt-2">
                        <input id="username" name="username" type="email" oninput="{ edit }" value="{ state.username }" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6">
                    </div>
                </div>

                <div>
                    <div class="flex items-center justify-between">
                        <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                        <div class="text-sm">
                            <a href="#" class="font-semibold text-blue-600 hover:text-blue-500">Password dimenticata?</a>
                        </div>
                    </div>
                    <div class="mt-2">
                        <input id="password" name="password" type="password" oninput="{ edit }" value="{ state.password }" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6">
                    </div>
                </div>

                <div>
                    <button type="submit" class="flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Accedi</button>
                </div>
            </form>
        </div>

        <div if="{ state.is_error }" class="rounded-md bg-red-50 p-4 mt-5">
            <div class="flex">
                <div class="flex-shrink-0">
                    <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                    </svg>
                </div>
                <div class="ml-3">
                    <h3 class="text-sm font-medium text-yellow-800">Attenzione</h3>
                    <div class="mt-2 text-sm text-yellow-700">
                        <p>Errore di autenticazione:<br /><em>{ state.error_message }</em></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <script>
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import * as events from '/src/utils/events'

        export default {

            onBeforeMount(props, state) {

                document.documentElement.className = "h-full bg-white"
                document.body.classList = "h-full"

                // initial state
                this.state = {
                    username: '',
                    password: '',
                    is_error: false,
                    error_message: ''
                }

                eventBus.on(events.LOGIN_FAILED, this.login_failed)
            },

            onBeforeUnmount() {
                eventBus.off(events.LOGIN_FAILED, this.login_failed)
            },

            edit(e) {
                if (this.state.is_error) {
                    this.update({
                        is_error: false,
                        error_message: '',
                        [e.target.id]: e.target.value
                    })
                } else {
                    this.update({
                        [e.target.id]: e.target.value
                    })
                }
            },

            async submit(e) {
                e.preventDefault()
                const result = await StoreService.login(this.state.username, this.state.password)
                if (result.user) {
                    eventBus.trigger(events.LOGIN_SUCCESS, result.user)
                } else {
                    this.update({
                        is_error: true,
                        error_message: result.error.message
                    })
                }
            }
        }
    </script>
</login>