/* Riot v9.1.5, @license MIT */
import '../dependencies/@riotjs/dom-bindings/dist/dom-bindings.js';
import expressionTypes from '../dependencies/@riotjs/util/expression-types.js';
import { memoize } from '../dependencies/@riotjs/util/misc.js';

/**
 * Get the computed attribute names from the template instance
 * Since these attributes will not change we memoize the result of this computation
 * @param {TemplateChunk} template - template instance
 * @return {[]} list of attribute names that will be computed by the template expressions
 */
const getRootComputedAttributeNames = memoize(
  (template) =>
    template?.bindingsData?.[0].expressions?.reduce(
      (acc, { name, type }) =>
        type === expressionTypes.ATTRIBUTE ? [...acc, name] : acc,
      [],
    ) ?? [],
);

export { getRootComputedAttributeNames };
