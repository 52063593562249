<breadcrumb-item>
    <li>
        <div class="flex items-center">
            <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
            </svg>
            <a href="{ props.link }" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{ state.text }</a>
        </div>
    </li>

    <script>
        import eventBus from '/src/utils/event-bus'
        import * as events from '/src/utils/events'

        export default {
            onBeforeMount(props, state) {
                this.state = {
                    text: props.text
                }

                eventBus.on(events.UPDATE_BREADCRUMB, this.handleUpdateBreadcrumb)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.UPDATE_BREADCRUMB, this.handleUpdateBreadcrumb)
            },

            handleUpdateBreadcrumb(e) {
                if (e.ckey === this.props.ckey) {
                    this.update({
                        text: e.text
                    })
                }
            }
        }

    </script>
</breadcrumb-item>