<modal-add-esecuzione>
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div if="{ state.modal_visible }" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-out duration-300 { state.modal_visible ? 'opacity-100' : 'opacity-0' }"></div>

        <div if="{ state.modal_visible }" class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-start justify-center p-4 mt-12 text-center sm:p-0 sm:mt-0">
                <div class="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6 { state.modal_visible ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' }">
                    <div class="sm:flex sm:items-start mb-5">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Aggiungi esecuzione per { state.atleta }</h3>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">{ state.giorno }</p>
                            </div>
                        </div>
                    </div>
                    <form onsubmit="{
                                submit
                            }" autocomplete="off">
                        <input autocomplete="false" name="hidden" type="text" style="display:none;">
                        <input type="hidden" name="esecuzione_id" value="{ state.esecuzione_id }" />
                        <div class="col-span-full pb-3">
                            <combobox ckey="{ ckey_attrezzo }" label="Attrezzo" placeholder="Seleziona l'attrezzo"></combobox>
                        </div>
                        <div class="col-span-full pb-3">
                            <combobox ckey="{ ckey_esercizio }" label="Esercizio" placeholder="Seleziona l'esercizio" depends_on="attrezzo"></combobox>
                        </div>
                        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Salva</button>
                            <button type="button" onclick="{
                                        close_modal
                                    }" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Annulla</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <script>
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import { updateComboboxAttrezzi } from '/src/utils/functions'
        import { KEY_ATTREZZO, KEY_ESERCIZIO } from '/src/utils/constants'
        import * as events from '/src/utils/events'

        export default {

            ckey_attrezzo: KEY_ATTREZZO,
            ckey_esercizio: KEY_ESERCIZIO,

            onBeforeMount(props, state) {
                this.state = {
                    modal_visible: false,
                    attrezzi_items: [],
                    esecuzione_id: '',
                    attrezzo_id: '',
                    esercizio_id: '',
                    atleta: '',
                    giorno: ''
                }

                eventBus.on(events.OPEN_MODAL_ADD_ESECUZIONE, this.handleOpenModalAddEsecuzione)
                eventBus.on(events.SELECTED_COMBOBOX, this.handleSelectedCombobox)
            },

            async onMounted(props, state) {
                const resultAttrezzi = await StoreService.getItems(KEY_ATTREZZO, 'descrizione', 'asc', 0, '')
                const resultEsercizi = await StoreService.getItems(KEY_ESERCIZIO, 'descrizione', 'asc', 0, '', ['categoria', 'categoria.attrezzo'])

                if (resultAttrezzi.items && resultEsercizi.items) {
                    const attrezzi_items = []
                    for (let i = 0;
                    i < resultAttrezzi.items.length; i++) {
                        attrezzi_items.push({
                            value: resultAttrezzi.items[i].id,
                            label: resultAttrezzi.items[i].get('descrizione')
                        })
                    }

                    resultEsercizi.items.sort((a, b) => {
                        // Ottieni le descrizioni delle categorie attraverso il puntatore e il metodo get
                        const categoriaA = a.get("categoria").get("descrizione").toUpperCase(); // Usa get per accedere ai valori
                        const categoriaB = b.get("categoria").get("descrizione").toUpperCase();

                        if (categoriaA < categoriaB)
                            return -1;
                        if (categoriaA > categoriaB)
                            return 1;

                        // Se le descrizioni delle categorie sono uguali, confronta le descrizioni degli esercizi
                        const esercizioA = a.get("descrizione").toUpperCase(); // Anche qui usa get
                        const esercizioB = b.get("descrizione").toUpperCase();

                        if (esercizioA < esercizioB)
                            return -1;
                        if (esercizioA > esercizioB)
                            return 1;

                        return 0; // Se entrambe le descrizioni delle categorie e degli esercizi sono uguali
                    });

                    const esercizi_items = []
                    for (let i = 0; i < resultEsercizi.items.length; i++) {
                        esercizi_items.push({
                            value: resultEsercizi.items[i].id,
                            label: resultEsercizi.items[i].get('descrizione'),
                            sublabel: resultEsercizi.items[i].get('categoria') ? resultEsercizi.items[i].get('categoria').get('descrizione') : '',
                            depending: resultEsercizi.items[i].get('categoria') && resultEsercizi.items[i].get('categoria').get('attrezzo') ? resultEsercizi.items[i].get('categoria').get('attrezzo').id : ''
                        })
                    }
                    this.update({
                        attrezzi_items: attrezzi_items,
                        esercizi_items: esercizi_items
                    })
                } else {
                    if (resultAttrezzi.error) {
                        eventBus.trigger(events.GENERIC_ERROR, {
                            ckey: this.ckey,
                            code: resultAttrezzi.code,
                            error: resultAttrezzi.error
                        })
                    } else if (resultEsercizi.error) {
                        eventBus.trigger(events.GENERIC_ERROR, {
                            ckey: this.ckey,
                            code: resultCategorie.code,
                            error: resultCategorie.error
                        })
                    }
                }
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.OPEN_MODAL_ADD_ESECUZIONE, this.handleOpenModalAddEsecuzione)
                eventBus.off(events.SELECTED_COMBOBOX, this.handleSelectedCombobox)
            },

            submit(e) {
                e.preventDefault()
                eventBus.trigger(events.SAVE, {
                    ckey: this.props.ckey,
                    id: this.state.esecuzione_id,
                    attrezzo_id: this.state.attrezzo_id,
                    esercizio_id: this.state.esercizio_id
                })
                this.close_modal()
            },

            close_modal() {
                this.update({
                    modal_visible: false,
                    esecuzione_id: '',
                    attrezzo_id: '',
                    esercizio_id: '',
                    atleta: '',
                    giorno: ''
                })
            },

            handleOpenModalAddEsecuzione(e) {
                if (e.ckey === this.props.ckey) {
                    this.update({
                        modal_visible: true,
                        atleta: e.atleta,
                        giorno: e.giorno
                    })

                    eventBus.trigger(events.UPDATE_COMBOBOX, {
                        ckey: KEY_ATTREZZO,
                        items: this.state.attrezzi_items
                    })

                    eventBus.trigger(events.UPDATE_COMBOBOX, {
                        ckey: KEY_ESERCIZIO,
                        items: this.state.esercizi_items
                    })
                }
            },

            handleSelectedCombobox(e) {
                switch (e.ckey) {
                    case KEY_ATTREZZO:
                        this.update({
                            attrezzo_id: e.value
                        })
                        break;
                    case KEY_ESERCIZIO:
                        this.update({
                            esercizio_id: e.value
                        })
                        break;
                }
            }
        }
    </script>
</modal-add-esecuzione>
