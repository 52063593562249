<generic-table>
    <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th each='{ (th_item, index) in props.th_array }' is='table-th' ckey='{ props.ckey }' column='{ th_item.column }' sorting='{ th_item.sorting }' first='{ index === 0 }' hidemd='{ th_item.hidemd }' hidesm='{ th_item.hidesm }'>{ th_item.label }</th>
                            <th ckey='{ props.ckey }' is='table-th' title='' last='1'></th>
                        </tr>
                    </thead>
                    <tbody class="bg-white">
                        <tr each="{ item in state.items.results }" class="even:bg-gray-50">
                            <td each='{ (td_item, index) in props.td_array }' is='table-td' highlight='{ td_item.highlight }' first='{ index === 0 }' hidemd='{ td_item.hidemd }' hidesm='{ td_item.hidesm }'><raw html="{ get_td_value(item, td_item) }"></raw></td>
                            <td is='table-td' last='1'>
                                <a class="inline-block mr-1" href="{ window.location }/edit/{ item.id }">
                                    <button type="button" class="btn-round-edit">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                            <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                                            <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                                        </svg>
                                        <span class="sr-only">Modifica item</span>
                                    </button>
                                </a>
                                <button type="button" class="btn-round-delete" onclick="{
                                            open_modal.bind(this, item.id)
                                        }">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                        <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clip-rule="evenodd" />
                                    </svg>
                                    <span class="sr-only">Cancella item</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <pagination ckey='{ props.ckey }'></pagination>
        </div>
        <modal-delete ckey="{ props.ckey }" title="{ props.title_delete.bind(this) }" text="{ props.text_delete.bind(this) }"></modal-delete>
    </div>

    <script>
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import * as events from '/src/utils/events'

        export default {

            onBeforeMount(props, state) {
                this.state = {
                    items: {
                        count: 0,
                        results: []
                    },
                    page: 1,
                    column: '',
                    direction: 'asc',
                    search_text: ''
                }

                eventBus.on(events.PAGINATION_CHANGE, this.handlePaginationChange)
                eventBus.on(events.SORTING_CHANGE, this.handleSortChange)
                eventBus.on(events.DELETE, this.handleDelete)
                eventBus.on(events.SEARCH, this.handleSearch)
                eventBus.on(events.REFRESH, this.handleRefresh)
            },

            async onMounted(props, state) {
                await this.refresh()
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.PAGINATION_CHANGE, this.handlePaginationChange)
                eventBus.off(events.SORTING_CHANGE, this.handleSortChange)
                eventBus.off(events.DELETE, this.handleDelete)
                eventBus.off(events.SEARCH, this.handleSearch)
                eventBus.off(events.REFRESH, this.handleRefresh)
            },

            async handlePaginationChange(e) {
                if (e.ckey === this.props.ckey) {
                    const result = await StoreService.getItems(this.props.ckey, this.state.column, this.state.direction, e.page, this.state.search_text)
                    if (result.items) {
                        this.update({
                            items: result.items,
                            page: e.page
                        })
                        this.itemsChanged()
                    } else {
                        eventBus.trigger(events.GENERIC_ERROR, {
                            ckey: this.props.ckey,
                            code: result.code,
                            error: result.error
                        })
                    }
                }
            },

            async handleSortChange(e) {
                if (e.ckey === this.props.ckey) {
                    const result = await StoreService.getItems(this.props.ckey, e.column, e.direction, this.state.page, this.state.search_text)
                    if (result.items) {
                        this.update({
                            items: result.items,
                            column: e.column,
                            direction: e.direction
                        })
                        this.itemsChanged()
                    } else {
                        eventBus.trigger(events.GENERIC_ERROR, {
                            ckey: this.props.ckey,
                            code: result.code,
                            error: result.error
                        })
                    }
                }
            },

            async handleSearch(e) {
                if (e.ckey === this.props.ckey) {
                    const result = await StoreService.getItems(this.props.ckey, this.state.column, this.state.direction, 1, e.search_text)
                    if (result.items) {
                        this.update({
                            items: result.items,
                            search_text: e.search_text,
                            page: 1,
                        })
                        this.itemsChanged()
                    } else {
                        eventBus.trigger(events.GENERIC_ERROR, {
                            ckey: this.props.ckey,
                            code: result.code,
                            error: result.error
                        })
                    }
                }
            },

            async handleDelete(e) {
                if (e.ckey === this.props.ckey) {
                    await StoreService.deleteItem(this.props.ckey, e.item_id);
                    const result = await StoreService.getItems(this.props.ckey, this.state.column, this.state.direction, this.state.page, this.state.search_text)
                    this.update({
                        items: result.items
                    })
                    this.itemsChanged()
                }
            },

            async handleRefresh(e) {
                if (e.ckey === this.props.ckey) {
                    await this.refresh()
                }
            },

            async refresh() {
                const result = await StoreService.getItems(this.props.ckey, this.state.column, this.state.direction, this.state.page, this.state.search_text)
                if (result.items) {
                    this.update({
                        items: result.items
                    })
                    this.itemsChanged()
                } else {
                    eventBus.trigger(events.GENERIC_ERROR, {
                        ckey: this.props.ckey,
                        code: result.code,
                        error: result.error
                    })
                }
            },

            itemsChanged() {
                eventBus.trigger(events.ITEMS_CHANGED, {
                    ckey: this.props.ckey,
                    items: this.state.items,
                    page: this.state.page,
                    column: this.state.column,
                    direction: this.state.direction,
                    search_text: this.state.search_text
                })
            },

            open_modal(item_id) {
                eventBus.trigger(events.OPEN_MODAL_DELETE, {
                    ckey: this.props.ckey,
                    item_id: item_id
                })
            },

            get_td_value(item, td_item) {
                if (td_item.field) {
                    return item.get(td_item.field)
                } else if (typeof td_item.fun === 'function') {
                    return td_item.fun(item)
                }
                return ''
            }
        }
    </script>
</generic-table>
