import StoreService from '/src/services/store'
import eventBus from '/src/utils/event-bus'
import { KEY_ATTREZZO } from '/src/utils/constants'

export async function updateComboboxAttrezzi() {
    const attrezzi = await StoreService.getItems(KEY_ATTREZZO, 'descrizione', 'asc', 0, '')
    const attrezzi_items = []
    for (let i = 0; i < attrezzi.length; i++) {
        attrezzi_items.push({
            value: attrezzi[i].id,
            label: attrezzi[i].get('descrizione')
        })
    }
    eventBus.trigger('update-combobox', {
        ckey: KEY_ATTREZZO,
        items: attrezzi_items,
        attrezzi: attrezzi
    })
}