/* Riot v9.1.5, @license MIT */
import { template as create } from '../dependencies/@riotjs/dom-bindings/dist/dom-bindings.js';
import expressionTypes from '../dependencies/@riotjs/util/expression-types.js';
import bindingTypes from '../dependencies/@riotjs/util/binding-types.js';

/**
 * Factory function to create the component templates only once
 * @param   {Function} template - component template creation function
 * @param   {RiotComponentWrapper} componentWrapper - riot compiler generated object
 * @param   {Function} getChildComponent - getter function to return the children components
 * @returns {TemplateChunk} template chunk object
 */
function componentTemplateFactory(
  template,
  componentWrapper,
  getChildComponent,
) {
  return template(
    create,
    expressionTypes,
    bindingTypes,
    getChildComponent,
  )
}

export { componentTemplateFactory };
