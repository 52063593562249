<edit-atleta>
    <div>
        <div>
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="/{ ckey }" text="Anagrafica atleti" />
                <breadcrumb-item ckey="{ ckey }/id" link="" text="Crea atleta" />
            </breadcrumb>
        </div>
        <heading-page ckey="{ ckey }/id" text="Crea atleta" link="/{ ckey }/create" />
        <div class='mt-8'>
            <form method="POST" onsubmit="{
                        submit
                    }">
                <input type="hidden" name="item_id" value="{ state.item_id }" />
                <div class="space-y-12">
                    <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <h2 class="text-base font-semibold leading-7 text-gray-900">Informazioni personali.</h2>
                            <p class="mt-1 text-sm leading-6 text-gray-600">Inserisci i dati dell'atleta.</p>
                        </div>

                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <div class="sm:col-span-3">
                                <input-text ckey="fname" label="Nome" value="{ state.fname }" oninput="{ edit }" required="required"></input-text>
                            </div>
                            <div class="sm:col-span-3">
                                <input-text ckey="lname" label="Cognome" value="{ state.lname }" oninput="{ edit }" required="required"></input-text>
                            </div>
                            <div class="col-span-full">
                                <input-text ckey="email" inputtype="email" label="Indirizzo email" value="{ state.email }" oninput="{ edit }" required="required">Se l'atleta non ha un indirizzo email, usa <em>nome.cognome@ginnasticatritium.it</em>. Attenzione alle omonimie.</input-text>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button-button text="Cancella"></button-button>
                    <button-submit text="Salva"></button-submit>
                </div>
            </form>
        </div>
    </div>

    <script>
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import { router } from '@riotjs/route'
        import { KEY_ATLETA } from '/src/utils/constants'
        import * as events from '/src/utils/events'

        export default {

            ckey: KEY_ATLETA,

            onBeforeMount(props, state) {
                this.state = {
                    item_id: '',
                    fname: '',
                    lname: '',
                    email: ''

                }
            },

            async onMounted(props, state) {
                if (props.item) {
                    const item = await StoreService.getItem(this.ckey, props.item)
                    this.update({
                        item_id: item.id,
                        fname: item.get('fname'),
                        lname: item.get('lname'),
                        email: item.get('username')
                    })
                    // Aggiorna il breadcrumb con il nome dell'atleta
                    eventBus.trigger(events.UPDATE_BREADCRUMB, {
                        ckey: this.ckey + '/id',
                        text: `Modifica atleta ${item.get('fname')} ${item.get('lname')}`
                    })
                    // Aggiorna la testata con il nome dell'atleta
                    eventBus.trigger(events.UPDATE_HEADING, {
                        ckey: this.ckey + '/id',
                        text: `Modifica atleta ${item.get('fname')} ${item.get('lname')}`
                    })
                } else {
                    this.update({
                        item_id: '',
                        fname: '',
                        lname: '',
                        email: ''
                    })
                }
            },

            edit(e) {
                this.update({
                    [e.target.id]: e.target.value
                })
            },

            async submit(e) {
                e.preventDefault()
                const result = await StoreService.saveAtleta(this.state.item_id, this.state.fname, this.state.lname, this.state.email)
                if (result.item) {
                    const path = '/' + this.ckey
                    history.pushState({}, '', path)
                    router.push(path)
                } else {
                    eventBus.trigger(events.OPEN_MODAL_ERROR, {
                        ckey: "error",
                        title: "Errore nel salvataggio dell'atleta",
                        text: result.error
                    })
                }
            }
        }
    </script>
</edit-atleta>
