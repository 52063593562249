<esercizi-atleta>
    <div>
        <div>
            <breadcrumb></breadcrumb>
        </div>
        <heading-page ckey="{ ckey }" text="Esercizi">
            <combobox ckey="atleta" label="" placeholder="Seleziona l'atleta" buttons="1"></combobox>
        </heading-page>
        <div class="grid md:grid-cols-2 gap-4">
            <div class='mt-8 overflow-hidden'>
                <choose-week></choose-week>
                <div class='mt-8'>
                    <div class="overflow-x-auto">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th ckey='{ ckey }' column='giorno' is='table-th' first='1' no-sorting='1' ></th>
                                    <th ckey='{ ckey }' column='nome' is='table-th' no-sorting='1' >Atleta</th>
                                    <th each="{ attrezzo in state.attrezzi }" ckey='{ ckey }' column="{ attrezzo.id }" is='table-th' no-sorting='1' ><img src="{ attrezzo.get('icona') ? attrezzo.get('icona').url() : '' }" alt="attrezzo.get('descrizione')" /></th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <template each="{ item in state.esecuzioni_giorno }" if="{ item.data_esecuzione !== somma }">
                                    <tr if="{ item.atleti.length }" each="{ (a, index) in item.atleti }">
                                        <td if="{ index === 0 }" rowspan="{ item.atleti.length }" is='table-td' first='1' highlight='1' condensed='1'>{ moment(item.data_esecuzione).format('ddd D') }</td>
                                        <td is='table-td' highlight='1' condensed='1'>
                                            <div class='flex items-center'>
                                                <button type="button" onclick='{
                                                                select_day_user.bind(this, a.atleta_id, item.data_esecuzione)
                                                            }' class="rounded-full bg-teal-600 p-1 text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                                                        <path fill-rule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clip-rule="evenodd" />
                                                    </svg>
                                                </button> <span class='ml-2'><raw html="{ a.atleta_name }"></raw></span>
                                            </div>
                                        </td>
                                        <td each="{ attrezzo in state.attrezzi }" is='table-td' condensed='1' center='1'>
                                            <template each="{ attr in a.attrezzi }" if="{ attrezzo.id === attr.attrezzo_id }">
                                                <span class='text-pink-600 font-bold p-1 min-w-7 inline-block text-center mb-1'>{ attr.parte }</span><br />
                                                <span class='text-purple-600 font-bold p-1 min-w-7 inline-block text-center'>{ attr.intero }</span>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr if="{ !item.atleti.length }">
                                        <td is='table-td' first='1' highlight='1' condensed='1'>{ moment(item.data_esecuzione).format('ddd D') }</td>
                                        <td is='table-td' colspan="{ state.attrezzi.length }" last='1' condensed='1'></td>
                                    </tr>
                                </template>
                            </tbody>
                            <tfoot if="{ state.atleta }">
                                <template each="{ item in state.esecuzioni_giorno }" if="{ item.atleti.length && item.data_esecuzione === somma }">
                                    <tr each="{ (a, index) in item.atleti }" if="{ a.atleta_id === state.atleta.id }">
                                        <td colspan="2" is='table-td'first='1' >SOMMA:</td>
                                        <td each="{ attrezzo in state.attrezzi }" is='table-td' condensed='1' center='1'>
                                            <template each="{ attr in a.attrezzi }" if="{ attrezzo.id === attr.attrezzo_id }">
                                                <span class='text-pink-600 font-bold p-1 min-w-7 inline-block text-center mb-1'>{ attr.parte }</span><br />
                                                <span class='text-purple-600 font-bold p-1 min-w-7 inline-block text-center'>{ attr.intero }</span>
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
            <div class="mt-8 ">
                <div class="flex h-full flex-col border rounded-md border-gray-200 bg-white px-4 py-4 sm:px-6">
                    <header class="flex flex-none items-center justify-between border-b border-gray-200 pb-4 mb-4">
                        <div>
                            <h1 class="text-base font-semibold leading-6 text-gray-900">
                                <time datetime="2022-01-22" class="sm:hidden">{ moment(state.giorno).format('DD MMM, YYYY') }</time>
                                <time datetime="2022-01-22" class="hidden sm:inline">{ moment(state.giorno).format('DD MMM, YYYY') }</time>
                            </h1>
                            <p class="mt-1 text-sm text-gray-500">{ moment(state.giorno).format('dddd').charAt(0).toUpperCase() + moment(state.giorno).format('dddd').slice(1) }</p>
                        </div>
                        <div class="flex items-center">
                            <div class="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                                <button type="button" onclick="{
                                            change_date.bind(this, -1)
                                        }" class="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50">
                                    <span class="sr-only">Giorno precedente</span>
                                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                                <button type="button" onclick="{
                                            change_date.bind(this, 0)
                                        }" class="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">Oggi</button>
                                <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden"></span>
                                <button type="button" onclick="{
                                            change_date.bind(this, 1)
                                        }" class="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50">
                                    <span class="sr-only">Giorno successivo</span>
                                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                            <button type="button" disabled="{ state.atleta ? false : true }" onclick="{
                                        open_modal_add_esecuzione
                                    }" class="ml-4 md:ml-6 rounded-md bg-blue-600 px-1 md:px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:opacity-75 disabled:cursor-not-allowed">
                                <span class='hidden md:inline'>Aggiungi</span>
                                <span class='inline md:hidden'>+</span> esecuzione</button>
                        </div>
                    </header>
                    <div if="{ !state.atleta }" class="rounded-md bg-blue-50 p-4">
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <svg class="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
                                </svg>
                            </div>
                            <p class="text-sm text-blue-700 ml-3">Seleziona prima l'atleta</p>
                        </div>
                    </div>
                    <div if='{ state.atleta }' class="flow-root">
                        <ul if="{ state.esecuzioni.length }" role="list" class="-mb-8">
                            <li each="{ (esec, index) in state.esecuzioni }">
                                <div class="relative pb-4">
                                    <span if="{ index < (state.esecuzioni.length - 1) }" class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                    <div class="relative flex space-x-3">
                                        <div>
                                            <span class="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center ring-8 ring-white">
                                                <img src="{ esec.get('esercizio').get('iconaUrl') }" alt="" />
                                            </span>
                                        </div>
                                        <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                            <div>
                                                <p class="text-sm"><span class="text-gray-800 inline-block">{ esec.get('esercizio').get('descrizione') }</span> <span class="text-gray-400 inline-block ml-1">- { esec.get('esercizio').get('categoriaDescrizione') }</span></p>
                                                <span if="{ esec.get('esercizio').get('tipologia') === 'parte' }" class="bg-pink-600 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-white">
                                                    { esec.get('esercizio').get('tipologia') }
                                                </span>
                                                <span if="{ esec.get('esercizio').get('tipologia') === 'intero' }" class="bg-purple-600 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-white">
                                                    { esec.get('esercizio').get('tipologia') }
                                                </span>
                                            </div>
                                            <div class="flex">
                                                <div class="whitespace-nowrap text-right text-md text-gray-700 ml-4 mr-16">
                                                    <strong>{ esec.get('esercizio').get('punteggio') }</strong>
                                                </div>
                                                <div class="whitespace-nowrap text-right text-md">
                                                    <button type="button" onclick="{
                                                                open_modal_delete.bind(this, esec)
                                                            }">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-5 h-5 fill-red-500 hover:fill-red-700">
                                                            <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clip-rule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div if="{ !state.esecuzioni.length }" class="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <p class="text-sm text-yellow-700 ml-3">Nessun esercizio svolto in questa giornata</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-delete ckey="{ ckey }"></modal-delete>
        <modal-add-esecuzione ckey="{ ckey }"</modal-add-esecuzione>
    </div>

    <script>
        import moment from 'moment-with-locales-es6'
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import { KEY_ATLETA, KEY_ATTREZZO, KEY_ESECUZIONE, SOMMA } from '/src/utils/constants'
        import * as events from '/src/utils/events'

        export default {

            moment: moment,
            ckey: KEY_ESECUZIONE,

            onBeforeMount(props, state) {
                moment.locale('it')

                this.state = {
                    atleti: [],
                    attrezzi: [],
                    atleta: null,
                    esecuzioni_giorno: [],
                    esecuzioni: [],
                    giorno: moment().format('YYYY-MM-DD'),
                    start_date: moment().startOf('isoWeek').format('YYYY-MM-DD'),
                    end_date: moment().endOf('isoWeek').format('YYYY-MM-DD')
                }

                eventBus.on(events.CHANGE_WEEK, this.handleChangeWeek)
                eventBus.on(events.SELECTED_COMBOBOX, this.handleSelectedComboboxAtleta)
                eventBus.on(events.DELETE, this.handleDeleteEsecuzione)
                eventBus.on(events.SAVE, this.handleSaveEsecuzione)

                this.somma = SOMMA
            },

            async onMounted(props, state) {
                const resultAttrezzi = await StoreService.getItems(KEY_ATTREZZO)
                const resultAtleti = await StoreService.getItems(KEY_ATLETA)

                if (resultAttrezzi.items && resultAtleti.items) {
                    this.update({
                        atleti: resultAtleti.items,
                        attrezzi: resultAttrezzi.items
                    })
                    const atleti_items = []
                    for (let i = 0;
                    i < this.state.atleti.length; i++) {
                        atleti_items.push({
                            value: this.state.atleti[i].id,
                            label: this.state.atleti[i].get('lname') + " " + this.state.atleti[i].get('fname')
                        })
                    }
                    eventBus.trigger(events.UPDATE_COMBOBOX, {
                        ckey: KEY_ATLETA,
                        items: atleti_items
                    })
                } else {
                    if (resultAttrezzi.error) {
                        eventBus.trigger(events.GENERIC_ERROR, {
                            ckey: "error",
                            code: resultAttrezzi.code,
                            error: resultAttrezzi.error
                        })
                    } else if (resultAtleti.error) {
                        eventBus.trigger(events.GENERIC_ERROR, {
                            ckey: "error",
                            code: resultAtleti.code,
                            error: resultAtleti.error
                        })
                    }
                }
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.CHANGE_WEEK, this.handleChangeWeek)
                eventBus.off(events.SELECTED_COMBOBOX, this.handleSelectedComboboxAtleta)
                eventBus.off(events.DELETE, this.handleDeleteEsecuzione)
                eventBus.off(events.SAVE, this.handleSaveEsecuzione)
            },

            async handleSelectedComboboxAtleta(e) {
                if (e.ckey === KEY_ATLETA) {
                    const atleta = this.state.atleti.find(
                            item => item.id === e.value
                    )
                    this.update({
                        atleta: atleta,
                        esecuzioni: []
                    })
                    await this.update_calendar()
                    await this.find_esecuzioni()
                }
            },

            async handleDeleteEsecuzione(e) {
                if (e.ckey === this.ckey) {
                    await StoreService.deleteItem(this.ckey, e.item_id);
                    await this.update_calendar()
                    await this.find_esecuzioni()
                }
            },

            async handleSaveEsecuzione(e) {
                if (e.ckey === this.ckey) {
                    await StoreService.saveEsecuzione(e.id, e.esercizio_id, this.state.atleta.id, this.state.giorno);
                    await this.update_calendar()
                    await this.find_esecuzioni()
                }
            },

            async handleChangeWeek(e) {
                let start_date = moment(e.current_week).startOf('isoWeek').format('YYYY-MM-DD')
                const end_date = moment(e.current_week).endOf('isoWeek').format('YYYY-MM-DD')
                this.update({
                    start_date: start_date,
                    end_date: end_date
                })
                await this.update_calendar()
            },

            async change_date(delta) {
                if (delta > 0 || delta < 0) {
                    this.update({
                        giorno: moment(this.state.giorno).add(delta, 'd').format('YYYY-MM-DD')
                    })
                } else {
                    this.update({
                        giorno: moment().format('YYYY-MM-DD')
                    })
                }
                await this.find_esecuzioni()
            },

            select_day_user(atleta_id, data_esecuzione) {
                const atleta = this.state.atleti.find(atleta => atleta.id === atleta_id);
                this.update({
                    atleta: atleta,
                    giorno: data_esecuzione
                })
                this.find_esecuzioni()
                eventBus.trigger(events.SELECT_COMBOBOX, {
                    ckey: 'atleta',
                    item: atleta.id
                })
            },

            async update_calendar() {
                const data = await StoreService.getEsecuzioni(this.state.start_date, this.state.end_date, this.state.atleta ? this.state.atleta.id : null)
                this.update({
                    esecuzioni_giorno: data
                })
            },

            async find_esecuzioni() {
                if (this.state.atleta) {
                    const items = await StoreService.getEsecuzioniDettaglio(this.state.giorno, this.state.atleta.id)

                    this.update({
                        esecuzioni: items
                    })
                }
            },

            open_modal_delete(esecuzione) {
                eventBus.trigger(events.OPEN_MODAL_DELETE, {
                    ckey: this.ckey,
                    item_id: esecuzione.id,
                    title: "Eliminazione esecuzione",
                    text: "Stai per cancellare l'esecuzione dell'esercizio <strong>" + esecuzione.get('esercizio').get('descrizione') + "</strong>. L'azione è irreversibile. Vuoi continuare?"
                })
            },

            open_modal_add_esecuzione() {
                if (this.state.atleta) {
                    let giorno = moment(this.state.giorno).format('dddd DD MMMM')
                    giorno = giorno.charAt(0).toUpperCase() + giorno.slice(1);
                    eventBus.trigger(events.OPEN_MODAL_ADD_ESECUZIONE, {
                        ckey: this.ckey,
                        atleta: this.state.atleta.get('lname') + ' ' + this.state.atleta.get('fname'),
                        giorno: giorno
                    })
                }
            }
        }
    </script>
</esercizi-atleta>