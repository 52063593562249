<edit-attrezzo>
    <div>
        <div>
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="/{ ckey }" text="Anagrafica attrezzi" />
                <breadcrumb-item ckey="{ ckey }/id" link="" text="Crea attrezzo" />
            </breadcrumb>
        </div>
        <heading-page ckey="{ ckey }/id" text="Crea attrezzo" link="/{ ckey }/create" />
        <div class='mt-8'>
            <form method="POST" onsubmit="{
                        submit
                    }">
                <input type="hidden" name="item_id" value="{ state.item_id }" />
                <div class="space-y-12">
                    <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                        <div>
                            <h2 class="text-base font-semibold leading-7 text-gray-900">Informazioni generali.</h2>
                            <p class="mt-1 text-sm leading-6 text-gray-600">Inserisci i dati dell'attrezzo.</p>
                        </div>

                        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                            <div class="col-span-full">
                                <input-text ckey="descrizione" label="Nome dell'attrezzo" value="{ state.descrizione }" oninput="{ edit }" required="required"></input-text>
                            </div>
                            <div class="col-span-full">
                                <input-file ckey="{ ckey }/icona" label="Immagine"></input-file>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <button-back text="Indietro"></button-back>
                    <button-submit text="Salva"></button-submit>
                </div>
            </form>
        </div>
    </div>

    <script>
        import StoreService from '/src/services/store'
        import eventBus from '/src/utils/event-bus'
        import { route } from '@riotjs/route'
        import { KEY_ATTREZZO } from '/src/utils/constants'
        import * as events from '/src/utils/events'

        export default {

            ckey: KEY_ATTREZZO,

            onBeforeMount(props, state) {
                this.state = {
                    item_id: '',
                    descrizione: '',
                    icona: ''

                }

                eventBus.on(events.UPDATED_FILE, this.handleUpdatedFile)
            },

            async onMounted(props, state) {
                if (props.item) {
                    const item = await StoreService.getItem(this.ckey, props.item)
                    this.update({
                        item_id: item.id,
                        descrizione: item.get('descrizione'),
                        icona: item.get('icona') ? item.get('icona').url() : ''
                    })
                    // Aggiorna il breadcrumb con il nome dell'attrezzo
                    eventBus.trigger(events.UPDATE_BREADCRUMB, {
                        ckey: this.ckey + '/id',
                        text: `Modifica attrezzo ${item.get('descrizione')}`
                    })
                    // Aggiorna la testata con il nome dell'attrezzo
                    eventBus.trigger(events.UPDATE_HEADING, {
                        ckey: this.ckey + '/id',
                        text: `Modifica attrezzo ${item.get('descrizione')}`
                    })
                    // Aggiorna l'input file
                    eventBus.trigger(events.UPDATE_FILE, {
                        ckey: this.ckey + '/icona',
                        url: this.state.icona
                    })
                } else {
                    this.update({
                        item_id: '',
                        descrizione: '',
                        icona: ''
                    })
                }
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.UPDATED_FILE, this.handleUpdatedFile)
            },

            edit(e) {
                this.update({
                    [e.target.id]: e.target.value
                })
            },

            handleUpdatedFile(e) {
                if (e.ckey === this.ckey + '/icona') {
                    this.update({
                        icona: e.url
                    })
                }
            },

            async submit(e) {
                e.preventDefault()
                const result = await StoreService.saveAttrezzo(this.state.item_id, this.state.descrizione, this.state.icona)
                if (result.item) {
                    const path = '/' + this.ckey
                    history.pushState({}, '', path)
                    router.push(path)
                } else {
                    eventBus.trigger(events.OPEN_MODAL_ERROR, {
                        ckey: "error",
                        title: "Errore nel salvataggio dell'attrezzo",
                        text: result.error
                    })
                }
            }
        }
    </script>
</edit-attrezzo>
