<anagrafica-attrezzi>
    <div>
        <div>
            <breadcrumb>
                <breadcrumb-item ckey="{ ckey }" link="" text="Anagrafica attrezzi" />
            </breadcrumb>
        </div>
        <heading-page ckey="{ ckey }" text="Anagrafica attrezzi" link="/{ ckey }/create" search="1" refresh="1"></heading-page>
        <generic-table ckey='{ ckey }' th_array='{ get_th_array() }' td_array='{ get_td_array() }' title_delete="{ getTitleModalDelete.bind(this) }" text_delete="{ getTextModalDelete.bind(this) }"></generic-table>
    </div>

    <script>
        import eventBus from '/src/utils/event-bus'
        import { KEY_ATTREZZO } from '/src/utils/constants'
        import * as events from '/src/utils/events'

        export default {

            ckey: KEY_ATTREZZO,
            
            get_th_array() {
                const th_array = []
                th_array.push({
                    column: 'icona',
                    label: '',
                    sorting: false
                })
                th_array.push({
                    column: 'descrizione',
                    label: 'Nome attrezzo',
                    sorting: true
                })
                return th_array
            },

            get_td_array() {
                const td_array = []
                td_array.push({
                    fun: this.get_icona,
                    highlight: false
                })
                td_array.push({
                    field: 'descrizione',
                    highlight: true
                })
                return td_array
            },

            onBeforeMount(props, state) {
                this.state = {
                    items: {
                        count: 0,
                        results: []
                    }
                }

                eventBus.on(events.ITEMS_CHANGED, this.handleTableItemsChanged)
            },

            onBeforeUnmount(props, state) {
                eventBus.off(events.ITEMS_CHANGED, this.handleTableItemsChanged)
            },

            handleTableItemsChanged(e) {
                if (e.ckey === this.ckey) {
                    this.update({
                        items: e.items
                    })
                }
            },

            getTitleModalDelete(e) {
                if (e.ckey === this.ckey) {
                    return "Eliminazione attrezzo"
                }
            },

            getTextModalDelete(e) {
                if (e.ckey === this.ckey) {
                    const attrezzo = this.state.items.results.find(item => item.id === e.item_id)
                    return "Stai per cancellare l'attrezzo " + attrezzo.get('descrizione') + ". L'azione è irreversibile. Vuoi continuare?"
                }
            },
            
            get_icona(item) {
                let html = ''
                if(item.get('icona')) {
                    html = "<img class='w-24 rounded-full mx-auto' src=" + item.get('icona').url() + " />"
                }
                return html
            }
        }

    </script>
</anagrafica-attrezzi>
