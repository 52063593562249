/* Riot v9.1.5, @license MIT */
import { callOrAssign } from '../dependencies/@riotjs/util/functions.js';

/**
 * Compute the component current state merging it with its previous state
 * @param   {Object} oldState - previous state object
 * @param   {Object} newState - new state given to the `update` call
 * @returns {Object} new object state
 */
function computeComponentState(oldState, newState) {
  return {
    ...oldState,
    ...callOrAssign(newState),
  }
}

export { computeComponentState };
